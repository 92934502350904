(function ($, adTags, googletag, enableLazyLoad, memberId, tileData) {

    const getSizes = (strSizes) => {
        if (!strSizes) return [];
        return strSizes.split(",").map(x => x.split("x").map(y => {
            const parsed = parseInt(y);
            return isNaN(parsed) ? y : parsed;
        }));
    };

    const getAdTags = (strTags) => {
        if (!strTags) return [];
        return strTags.split("|").map(x => x.split(","));
    };

    const getKruxParam = (strKrux, paramName) => {
        if (!strKrux) return '';
        var vars = strKrux.split("/");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == paramName) {
                return pair[1];
            }
        }
        return null;
    };

    const getKruxTags = () => {
        var kruxSasHelper = window.KruxSasHelper;
        if (kruxSasHelper) {
            var sasTags = kruxSasHelper().getSasTags();
            return {
                "ksg": getKruxParam(sasTags, "ksg"),
                "kuid": getKruxParam(sasTags, "kuid"),
                "gaclientId": getKruxParam(kruxSasHelper().getGaTags(), "gaclientId")
            };
        }

        console.warn("KruxSasHelper not loaded");
        return {};
    };

    const getQueryParam = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return null;
    };

    const getTitle = () => {
        // Extract article title from last section of url
        var sections = window.location.pathname.split('/');
        var title = "";
        while (sections.length > 0 && title === "") {
            title = sections.pop();
        }
        return title;
    };

    const getAdverts = () => $("div[data-ad-path]").map((index, elm) => {
        var target = $(elm);
        var path = target.data("adPath");
        var tags = target.data("adTags");
        var lazy = String(target.data('lazy')).toLowerCase() === "true";

        if (window.csnGoogleAdvertData) {
            var tile = target.data('ad-tile');
            if (tile) {
                var newPath = window.csnGoogleAdvertData[`tile${tile}`];
                if (newPath) {
                    path = newPath;
                }
            }
        }

        if (tileData) {
            var tile = target.data('ad-tile');
            if (tile) {
                var data = tileData[`tile${tile}`];
                if (data) {
                    path = data.path || path;
                    tags = data.tags || tags;
                    lazy = data.lazy || lazy;
                    if (data.id) {
                        $(elm).attr("id", data.id);
                    }
                }
            }
        }

        return {
            targetId: $(elm).attr("id"),
            path: path,
            sizes: getSizes($(elm).data("adReplaceSize") || $(elm).data("adSizes")),
            tags: getAdTags(tags),
            outOfPage: String(target.data('adOutOfPage')).toLowerCase() === "true",
            lazy: lazy
        };
    }).get();

    const loadLazyAd = (ad) => {
        const script = document.createElement('script');
        script.appendChild(document.createTextNode(
            `googletag.cmd.push(function () { googletag.display('${ad.targetId}'); });`));
        document.getElementById(ad.targetId).appendChild(script);
    };

    const loadAdverts = () => {
        const adverts = getAdverts();
        const pubads = googletag.pubads();

        //  Define slots
        for (let ad of adverts) {
            const slot = ad.outOfPage === true
                ? googletag.defineOutOfPageSlot(ad.path, ad.targetId)
                : googletag.defineSlot(ad.path, ad.sizes, ad.targetId);

            if (ad.lazy !== true) {
                slot.setCollapseEmptyDiv(true);
            }

            //  ad level targeting
            if (ad.tags) {
                ad.tags.forEach(tag => {
                    if (tag.length === 2)
                        slot.setTargeting(tag[0], tag[1]);
                });
            }

            slot.addService(pubads);
        }

        // Set page-level key-values and GPT settings
        pubads.setTargeting('title', [getTitle()])
              .setTargeting("adtest", [getQueryParam("adtest") || "false"]);

        //  Set targetting
        for (let key in adTags) {
            if (adTags.hasOwnProperty(key)) {
                pubads.setTargeting(key, adTags[key]);
            }
        }

        // Set Krux Tags
        let kruxTags = getKruxTags();
        Object.keys(kruxTags).forEach(function (key) {
            pubads.setTargeting(key, kruxTags[key]);
        });

        //  Configure settings and services
        if (enableLazyLoad === true) {
            // Enable lazy-loading for all ad slots
            pubads.enableLazyLoad({
                fetchMarginPercent: 50, // Fetch ads within 0.5 viewports
                renderMarginPercent: 0, // Render slots as soon as they enter the viewport
                mobileScaling: 2.0 // Double margins on mobile devices
            });
            pubads.setCentering(true);
        } else {
            pubads.collapseEmptyDivs();
            pubads.enableSingleRequest();
        }

        if (memberId && memberId !== "") {
            pubads.setPublisherProvidedId(memberId);
        }

        googletag.enableServices();

        //  Display Adverts
        for (let ad of adverts) {
            if (enableLazyLoad === true && ad.lazy === true) {
                loadLazyAd(ad);
            } else {
                googletag.display(ad.targetId);
            }
        }
    };

    googletag.cmd.push(() => loadAdverts());

})(jQuery, (window.google_advert_targeting_tags || {}), googletag, window.google_advert_enable_lazy_load, (window.google_advert_member_id || false), (window.google_advert_tile_data || false));